.shopping-cart-container {
  width: 100%;
  padding: 50px 100px 150px;
  display: flex;
  
  .shopping-cart-left-container {
    width: 600px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    
    .shopping-cart-left-title {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 187.5% */
    }
    
    .shopping-cart-left-delivery-information-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      
      .shopping-cart-left-delivery-information-method-container {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        
        .shopping-cart-left-delivery-information-method-title {
          color: #393939;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          min-width: 120px;
        }
      }
      
      .shopping-cart-left-delivery-information-address-container {
        width: 100%;
        display: flex;
        gap: 20px;

        .shopping-cart-left-delivery-information-address-title {
          color: #393939;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          min-width: 120px;
        }
      }
    }
    
    .shopping-cart-left-delivery-payment-container {
      .shopping-cart-left-delivery-payment-type-container {
        display: flex;
        align-items: center;
        
        .shopping-cart-left-delivery-payment-type-title {
          display: flex;
          width: 130px;
          padding: 5px 0;
          align-items: flex-start;
          gap: 10px;
          flex-shrink: 0;
          color: #00606A;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          
          margin-right: 16px;
        }
        
        .shopping-cart-left-delivery-payment-type-group {
          .shopping-cart-left-delivery-payment-type-radio-container {
            display: inline-flex;
            align-items: center;
            
            span.ant-radio + span {
              display: flex;
              align-items: center;
              justify-content: center;
              
              img {
                max-width: 25px;
                max-height: 25px;
              }
            }
          }
        }
      }
      
      .shopping-cart-left-delivery-payment-form-container {
        margin-bottom: 20px;
        width: 100%;

        .shopping-cart-left-delivery-payment-form-row {
          display: flex;
          gap: 0 20px;

          .ant-form-item {
            flex: 1;
          }
        }

        .ant-form-item {
          margin-bottom: 20px;

          .ant-form-item-label {
            padding-bottom: 12px;

            label {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #666666;
            }

            .ant-form-item-required {
              &:after {
                content: '*';
                display: block;
                color: #EE1D52;
              }
            }
          }

          .ant-input {
            height: 45px;
            border: 1px solid rgba(102, 102, 102, 0.35);
            border-radius: 12px;
            font-size: 18px;
          }
        }
        
        .shopping-cart-left-delivery-payment-discount-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 20px;

          > div,
          > .ant-input {
            width: 250px;
          }
        }

        .shopping-cart-left-delivery-payment-form-btn {
          display: flex;
          height: 40px;
          padding: 15px 40px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 20px;
          background: #DA5864;

          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          cursor: pointer;
          user-select: none;
          
          align-self: center;
        }
      }

      .shopping-cart-left-delivery-payment-divider {
        padding: 20px 10px;
        display: flex;
        align-items: center;

        &:before,
        &:after {
          content: ' ';
          flex: 1;
          height: 2px;
          background-color: rgba(102, 102, 102, 0.25);
        }
      }
    }
    .shopping-cart-left-customer-credit-container {
      .customer-credit-text {
        color: #DA5864;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }
    }
    .shopping-cart-left-promo-error-message {
      color: #DA5864;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
    .shopping-cart-left-promo-success-message {
      color: #60A9A6;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      .shopping-cart-left-promo-remove {
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .shopping-cart-left-discount-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .shopping-cart-left-discount-apply-btn {
        margin-left: 20px;
        height: 50px;
        display: flex;
        flex-direction: row;
        min-width: 100px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 20px;
        background: #60A9A6;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
      }
    }
  }
  
  .shopping-cart-right-container {
    width: 640px;
    margin: 10px 20px;
  }
}
