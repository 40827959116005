.my-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  
  .my-list-show-more-btn-container {
    color: #DA5864;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 130% */
    text-transform: uppercase;
    text-align: center;
    
    cursor: pointer;
    user-select: none;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #60A9A6;
  border-color: #60A9A6;
  border-width: 1px;
}

.product-item-2-btn-disabled {
  width: 134px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #AEAEAE;

  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
}

.product-item-2-btn {
  width: 134px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #60A9A6;

  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
}

.select_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
