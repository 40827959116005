.my-subordinate-detail-mobile-container {
  width: 100%;

  .my-subordinate-detail-top-container {
    display: flex;
    align-items: center;
    gap: 30px;

    color: #393939;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    margin-bottom: 50px;

    cursor: pointer;
    user-select: none;
  }

  .my-subordinate-detail-btn {
    display: flex;
    height: 50px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 20px;
    background: #60A9A6;

    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }
}
