.my-profile-container {
  width: 100%;
  padding: 50px 100px;
  margin-bottom: 100px;
  display: flex;
  
  .my-profile-left-container {
    display: flex;
    width: 400px;
    padding: 20px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    background: #F6F6F6;
    
    .my-profile-left-user-info-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      
      .my-profile-left-user-info-upload-container {
        width: 159.783px;
        height: 156.383px;
        border-radius: 159.783px;
        overflow: hidden;

        .ant-upload {
          width: 100% !important;
          height: 100% !important;
          margin-inline-end: 0 !important;
          margin-bottom: 0 !important;
        }
      }
      
      .my-profile-left-user-info-avatar-container {
        width: 159.783px;
        height: 156.383px;
        flex-shrink: 0;
        border-radius: 159.783px;
        border: 4px solid #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        font-size: 90px;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .my-profile-left-user-info-username-container {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 187.5% */
      }
    }
    
    .my-profile-left-menu-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      
      .my-profile-left-menu-item-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        border-radius: 10px;
        background: rgba(141, 141, 141, 0.20);

        color: #393939;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 156.25% */
        
        cursor: pointer;
        user-select: none;
      }
      
      .my-profile-left-menu-item-active-container {
        background: #60A9A6;
        color: #FFFFFF;
      }
    }
  }
  
  .my-profile-right-container {
    flex: 1;
    padding: 10px 50px;
    
    .my-profile-right-profile-information-container {
      padding-right: 140px;
    }
    
    .my-profile-right-order-container {
      max-width: 840px;
    }
  }
}
