.product-item-2-mobile-container {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;

  .product-item-2-img-container {
    width: 100%;
    height: 40.5vw;
    //height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    .product-item-2-promotion-icon-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 56px;
      height: 56px;
      cursor: pointer;
      user-select: none;
      img {
        max-width: 100%;
        max-height: 100%;
        rotate: -30deg;
      }
    }
  }

  .product-item-2-placeholder {
    height: 30px;
  }

  .product-item-2-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    .product-item-2-des {
      color: #393939;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 162.5% */
      white-space: pre;
    }

    .product-item-2-price {
      color: #ACACAC;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      max-width: 100%;
      text-align: center;
    }

    .product-item-2-bottom-container {
      width: 99%;
      display: flex;
      align-items: center;
      gap: 10px;

      .product-item-2-bottom-input {
        width: 55px;
        height: 25px;
        border: 1px solid #C8C8C8;
        display: flex;
        align-items: center;
        border-radius: 0;

        .ant-input-number-input-wrap {
          height: 100%;

          .ant-input-number-input {
            height: 100%;
          }
        }
      }
    }

    .product-item-2-btn {
      width: 104px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background: #60A9A6;

      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;
    }
    .product-item-2-btn-disabled {
      width: 104px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background: #AEAEAE;

      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;
    }
  }

  .product-item-2-right-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 5px;
    top: 37vw;
    gap: 10px;

    .product-item-2-right-favorite-icon-container {
      width: 30px;
      cursor: pointer;
      user-select: none;
      margin-top: -3vw;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .product-item-2-right-total-price {
      color: #393939;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 162.5% */
    }

    .product-item-2-right-qty {
      color: #ACACAC;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      max-width: 100%;
      text-align: right;
    }
  }
}
