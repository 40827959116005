.my-address-mobile-container {
  width: 100%;

  .my-address-default-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .my-address-list-container {
      width: 100%;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .my-address-list-item-container {
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 10px;
        border: 1px solid #C4C4C4;

        .my-address-list-item-top-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .my-address-list-item-top-left-container {
            display: flex;
            align-items: center;
            gap: 10px;

            .my-address-list-item-top-left-title {
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .my-address-list-item-top-left-set-default {
              color: #1E4DF2;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              cursor: pointer;
              user-select: none;
            }
          }



          .my-address-list-item-top-op-container {
            display: flex;
            align-items: center;
            gap: 20px;

            .my-address-list-item-top-op-item-container {
              display: flex;
              height: 35px;
              padding: 10px 10px;
              justify-content: center;
              align-items: center;
              gap: 5px;
              border-radius: 16px;
              border: 1px solid #111;

              color: #111;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              cursor: pointer;
              user-select: none;
            }
          }
        }

        .my-address-list-item-address-2,
        .my-address-list-item-address-1 {
          display: flex;
          width: 249px;
          height: 32px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 36px;
        }

        .my-address-list-item-address-1 {
          margin-bottom: 5px;
        }
      }
    }

    .my-address-btn {
      display: flex;
      height: 50px;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 20px;
      background: #60A9A6;

      color: #FFF;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;

      width: max-content;
    }
  }

  .my-address-detail-page-container {

  }
}
