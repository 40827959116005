.content-header-mobile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;

  .content-header-des {
    color: #5C8692;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 154.5%; /* 23.175px */
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .content-header-title {
    color: #141414;
    text-align: center;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 58.5px */
    letter-spacing: -0.45px;
  }

  .content-header-title-2 {
    color: #141414;
    text-align: center;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 58.5px */
    letter-spacing: -0.45px;
    text-decoration-line: underline;
  }

  .content-header-banner-container {
    width: 100%;
    height: 50vw;
    padding: 25px 15px;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 10px;
    }
  }

  .content-header-banner-list-container {
    width: 100%;
    height: 350px;
    padding: 25px 15px;

    .content-header-banner-list-swiper {
      width: 100%;
      height: 100%;
    }

    .content-header-banner-list-item-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .content-header-banner-list-prev {
      transform: scale(2) translateX(-50%);
    }
    .content-header-banner-list-next {
      transform: scale(2) translateX(50%);
    }
  }

  .content-header-btn {
    display: flex;
    width: 100%;
    height: 25px;
    padding: 5px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    background: #60A9A6;

    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}
