.products_list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  
    .product_input_div {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
  
      .product_input {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding-top: 50px;
        width: 100%;
        position: fixed;
        background-color: white;
        z-index: 20;
        padding-bottom: 10px;
  
        .products-content-right-search-container {
          width: 724px;
          height: 45px;
          padding: 4px 20px;
          border-radius: 50px;
          background-color: #F6F6F6;
          border: none;
  
          .ant-input {
            background-color: #F6F6F6;
  
            &::placeholder {
              color: rgba(57, 57, 57, 0.50);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
            }
          }
  
          .products-content-right-search-icon-container {
            width: 24px;
            height: 24px;
            cursor: pointer;
            user-select: none;
  
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
  
      .products-content-right-product-group-container {
       .empty_img {
        display: flex;
        margin-top: 200px;
        flex-direction: column;
          justify-content: center;
          align-items: center;
       }
        .products_content_right_product_group_list {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 20px 40px;
          padding-right: 10%;
          padding-left: 10%;
          margin-top: 120px;
          margin-bottom: 30px;
          min-width: 680px;
  
          .products-content-right-product-group-item-container {
            width: 250px;
          }
        }
  
        .products-content-right-product-group-btn {
          color: #DA5864;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px; /* 130% */
          text-transform: uppercase;
          text-align: center;
          cursor: pointer;
          user-select: none;
        }
      }
    }
}