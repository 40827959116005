.home-mobile-container {
  width: 100%;

  .home-header-container {
    //padding: 0 5px;

    .content-header-title {
      padding: 0 5px;
    }
  }

  .home-product-group-container {
    padding: 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .home-product-group-title {
      color: #DA5864;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
  }

  .home-img-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 16px 16px;
    gap: 20px 0;

    .home-img-item-container {
      width: 48%;
      //height: 450px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }

  .home-whats-news-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
