.balance-detail-container {
  width: 1000px !important;
  height: calc(100vh - 130px);

  .ant-modal-content {
    height: 100%;
    padding: 20px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .ant-modal-body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  
  .balance-detail-title {
    color: #60A9A6;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px; /* 170% */
    text-transform: uppercase;
    
    span {
      font-weight: 500;
    }
  }
  
  .balance-detail-tip {
    color: #c2bdbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  }

  .balance-detail-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 10px 35px;
    
    .balance-detail-content-top-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .balance-detail-range-picker {
      width: max-content;
    }
    
    .balance-detail-content-fullscreen-control-container {
      font-size: 28px;
      cursor: pointer;
    }
    
    .balance-detail-iframe-container {
      flex: 1;
      
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  
  .balance-detail-spacing {
    height: 15px;
  }
}
