.my-subordinate-container {
  width: 100%;
  
  .my-subordinate-default-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .my-subordinate-list-container {
      width: 100%;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      
      .my-subordinate-list-item-container {
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #C4C4C4;

        .my-subordinate-list-item-content {
          padding: 10px 0;
          color: #000;
          font-size: 16px;
          font-style: normal;
          line-height: normal;
          display: flex;
          align-items: center;
          height: 32px;
          margin-bottom: 5px;
          
          .my-subordinate-list-item-label {
            width: 250px;
          }
        }
        
        .my-subordinate-list-item-bottom-container {
          display: flex;
          align-items: center;
          gap: 30px;

          .my-subordinate-list-item-bottom-active-btn {
            color: #1E4DF2;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
            user-select: none;
          }
          .my-subordinate-list-item-bottom-deactive-btn {
            color: #DA5864;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }

    .my-subordinate-btn {
      display: flex;
      height: 50px;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 20px;
      background: #60A9A6;

      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;

      min-width: 200px;
    }
  }
}
