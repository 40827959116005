.balance-detail-mobile-container {
  width: 1000px !important;
  height: calc(100vh - 130px);

  .ant-modal-content {
    height: 100%;
    padding: 20px 35px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-modal-body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .balance-detail-title {
    color: #60A9A6;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px; /* 170% */
    text-transform: uppercase;
    margin-bottom: 25px;

    span {
      font-weight: 500;
    }
  }

  .balance-detail-tip {
    color: #c2bdbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  }

  .balance-detail-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .balance-detail-content-top-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }


    .balance-detail-range-picker {
      width: max-content;
    }

    .balance-detail-iframe-container {
      flex: 1;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .balance-detail-spacing {
    height: 15px;
  }
}
