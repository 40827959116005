.my-profile-information-mobile-container {
  width: 100%;

  .my-profile-information-form-container {
    margin-bottom: 65px;
  }

  .my-profile-information-btn {
    width: 100%;
    display: flex;
    height: 40px;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    border-radius: 10px;
    background: #60A9A6;

    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border: none;

    &:hover {
      color: #FFF !important;
      border: none;
    }
  }

  .my-profile-information-disabled-btn {
    opacity: 0.6;
  }
}
