.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .login-title {
    color: #60A9A6;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px; /* 170% */
    text-transform: uppercase;
    margin-bottom: 70px;
    
    span {
      font-weight: 500;
    }
  }

  .login-content-container {
    width: 595px;
    
    .login-form-container {
      margin-bottom: 80px;
      width: 100%;

      .ant-form-item {
        margin-bottom: 40px;

        .ant-form-item-label {
          padding-bottom: 12px;

          label {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #666666;
          }

          .ant-form-item-required {
            &:after {
              content: '*';
              display: block;
              color: #EE1D52;
            }
          }
        }

        .ant-input {
          height: 56px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          border-radius: 12px;
          font-size: 18px;
          padding: 4px 11px;
        }

        .ant-input-password {
          border: none;
          padding: 0;
          border-radius: 12px;
          
          .ant-input-suffix {
            margin-inline-start: 0;
          }
        }
      }
      
      .login-form-password-container {
        position: relative;
        
        .login-form-show-hide-password {
          position: absolute;
          top: 0;
          right: 0;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;

          color: rgba(102, 102, 102, 0.80);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
          user-select: none;
        }
        .login-form-options-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          right: 0;
          top: 100px;
          width: 100%;
          .login-form-remember-me {
            color: rgba(102, 102, 102, 0.8);
            height: 22px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            span {
              color: rgba(102, 102, 102, 0.8);
            }
          }
          .login-form-forget-password {
            color: #60A9A6;
            height: 22px;
            display: flex;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
            user-select: none;
          } 
        }
      }
    }

    .login-btn {
      display: flex;
      height: 50px;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 20px;
      background: #60A9A6;

      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;
    }
    
    .login-divider {
      padding: 40px 0;
      display: flex;
      align-items: center;
      gap: 23px;
      
      &:before,
      &:after {
        content: ' ';
        flex: 1;
        height: 2px;
        background-color: rgba(102, 102, 102, 0.25);
      }
    }
    
    .login-btn2 {
      display: flex;
      height: 50px;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 20px;
      border: 1px solid #DA5864;
      background: #FFF;

      color: #DA5864;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;
    }
  }
}
