.products-content-mobile-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  flex-direction: column;

  .products-content-filter-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    gap: 30px;
    height: 60px;
    transition: height .5s ease-in-out;
    margin-top: 20px;

    &.extended {
      height: calc(100vh - 218px);
    }

    &.fixed {
      position: fixed;
      top: 118px;
    }

    &.stickBottom {
      position: absolute;
      bottom: 0px;
    }

    .products-content-filter-top-container {
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #000;
      position: absolute;

      .products-content-filter-top-title {
        color: #393939;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .products-content-filter-top-clear-filter {
        color: #DA5864;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        user-select: none;
      }
    }

    .products-content-filter-menu-container {
      margin-top: 70px;
      overflow-y: scroll;
    }
  }

  .products-content-right-container {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    min-height: calc(100vh - 118px);

    .products-content-right-title-container {
      display: flex;
      align-items: center;
      padding: 10px 0;
      gap: 10px;
      color: #393939;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      position: absolute;
      z-index: 1;
      background-color: #FFFFFF;
      width: 100%;

      &.fixed {
        position: fixed;
        top: 118px;
      }

      &.stickTop {
        position: absolute;
        top: 0px;
      }

      .products-content-right-title-icon-container {
        width: 24px;
        height: 24px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .products-content-right-search-sort-container {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 94px;

      .products-content-right-search-container {
        width: 70%;
        height: 45px;
        padding: 4px 20px;
        border-radius: 50px;
        background-color: #F6F6F6;
        border: none;

        .ant-input {
          background-color: #F6F6F6;

          &::placeholder {
            color: rgba(57, 57, 57, 0.50);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }

        .products-content-right-search-icon-container {
          width: 24px;
          height: 24px;
          cursor: pointer;
          user-select: none;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .products-content-right-sort-container {
        display: flex;
        align-items: center;
        gap: 20px;

        .products-content-right-sort-select-container {
          width: 99px;

          .ant-select-selector {
            height: 45px;
            border: 1px solid rgba(102, 102, 102, 0.35);
            border-radius: 20px;
            color: #262626;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            background: #F6F6F6;

            .ant-select-selection-search-input {
              height: 100%;
            }

            .ant-select-selection-placeholder {
              height: 100%;
              line-height: 43px;
            }

            .ant-select-selection-item {
              height: 100%;
              line-height: 43px;
            }
          }
          .products-content-right-sort-select-option-icon {
            margin-left: 5px;
            color: #a9a3a3;
          }
        }
      }
    }

    .products-content-right-product-group-container {

      .products-content-right-product-group-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px 10px;
        margin-bottom: 30px;

        .products-content-right-product-group-item-container {
          width: 48%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .products-content-right-product-group-btn {
        color: #DA5864;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 130% */
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}

.products-content-right-sort-select-popup-container {
  .ant-select-item-option,
  .ant-select-item {
    height: 100%;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .products-content-right-sort-select-option-label {
      }
    }
  }
}
