.home-container {
  width: 100%;
  
  .home-header-container {
    padding: 0 100px;
    
    .content-header-title {
      padding: 0 80px;
    }
  }
  
  .home-product-group-container {
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    
    .home-product-group-title {
      color: #DA5864;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
  }
  
  .home-img-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 100px 100px;
    gap: 200px 0;
    
    .home-img-item-container {
      width: 600px;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        object-fit: cover;
      }
    }
  }
  
  .home-whats-news-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
