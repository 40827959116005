.products-content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding-bottom: 60px;

  .products_mask {
    display: flex;
    width: 340px;
    // height: 100%;
    height: calc(100vh - 118px);
    position: absolute;
    z-index: 2;
    left: 0;
    background-color: #FFFFFF;
    &.mask_fixed {
      position: fixed;
      top: 118px;
    }
  }

  .products-content-filter-container {
    display: flex;
    flex-direction: column;
    width: 292px;
    padding: 10px;
    // gap: 30px;
    position: absolute;
    height: calc(100vh - 118px);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #FAFAFA;
    z-index: 3;

    &.fixed {
      position: fixed;
      top: 118px;
      background-color: #FaFaFa;
    }

    &.stickBottom {
      position: absolute;
      bottom: 0px;
    }

    .products-content-filter-top-container {
      width: calc(292px - 20px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #000;
      position: absolute;

      .products-content-filter-top-title {
        color: #393939;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .products-content-filter-top-clear-filter {
        color: #DA5864;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        user-select: none;
      }
    }

    .products-content-filter-menu-container {
      margin-top: 70px;
      overflow-y: scroll;
      background-color: #FAFAFA;
    }
  }

  .products-content-right-container {
    flex: 1;
    padding: 10px;
    margin-left: 340px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    min-height: calc(100vh - 118px);

    .products-content-right-title-container {
      display: flex;
      align-items: center;
      padding: 10px 0;
      gap: 10px;
      color: #393939;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      position: absolute;
      z-index: 1;
      background-color: #FFFFFF;
      width: 100%;

      &.fixed {
        position: fixed;
        top: 118px;
      }

      &.stickTop {
        position: absolute;
        top: 0px;
      }

      .products-content-right-title-icon-container {
        width: 24px;
        height: 24px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .products-content-right-search-sort-container {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 94px;

      .products-content-right-search-container {
        width: 724px;
        height: 45px;
        padding: 4px 20px;
        border-radius: 50px;
        background-color: #F6F6F6;
        border: none;

        .ant-input {
          background-color: #F6F6F6;

          &::placeholder {
            color: rgba(57, 57, 57, 0.50);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }

        .products-content-right-search-icon-container {
          width: 24px;
          height: 24px;
          cursor: pointer;
          user-select: none;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .products-content-right-sort-container {
        display: flex;
        align-items: center;
        gap: 20px;

        .products-content-right-sort-select-container {
          width: 188px;

          .ant-select-selector {
            height: 45px;
            border: 1px solid rgba(102, 102, 102, 0.35);
            border-radius: 20px;
            color: #262626;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            background: #F6F6F6;

            .ant-select-selection-search-input {
              height: 100%;
            }

            .ant-select-selection-placeholder {
              height: 100%;
              line-height: 43px;
            }

            .ant-select-selection-item {
              height: 100%;
              line-height: 43px;
            }
          }
          .products-content-right-sort-select-option-icon {
            margin-left: 5px;
            color: #a9a3a3;
          }
        }
      }
    }

    .products-content-right-product-group-container {

      .products-content-right-product-group-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 20px 90px;
        margin-bottom: 50px;

        .products-content-right-product-group-item-container {
          width: 250px;
        }
      }

      .products-content-right-product-group-btn {
        color: #DA5864;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 130% */
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        user-select: none;
      }
    }
  }
  :where(.css-dev-only-do-not-override-2htziv).ant-menu-light.ant-menu-root.ant-menu-inline, 
  :where(.css-dev-only-do-not-override-2htziv).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline, 
  :where(.css-dev-only-do-not-override-2htziv).ant-menu-light.ant-menu-root.ant-menu-vertical, 
  :where(.css-dev-only-do-not-override-2htziv).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical,
  :where(.css-2htziv).ant-menu-light.ant-menu-root.ant-menu-inline,
  :where(.css-2htziv).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline,
  :where(.css-2htziv).ant-menu-light.ant-menu-root.ant-menu-vertical,
  :where(.css-2htziv).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: unset;
  }
}

.products-content-right-sort-select-popup-container {
  .ant-select-item-option,
  .ant-select-item {
    height: 100%;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .products-content-right-sort-select-option-label {
      }
    }
  }
}
