.my-qrcode-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  
  .my-qrcode-img-container {
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  
  .my-qrcode-btn-container {
    display: flex;
    height: 50px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: #60A9A6;

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border: none;
    
    &:hover {
      color: #FFF !important;
      border: none;
    }
    
    .my-qrcode-btn-icon {
      font-size: 18px;
    }
  }
  
  .my-qrcode-btn-disabled-container {
    opacity: 0.6;
  }
}
