.o-success-mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  .o-success-img-container {
    width: 144px;
    height: 125px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .o-success-content-container {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    gap: 23px;

    .o-success-content-title-container {
      color: #616161;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 15px */
    }

    .o-success-content-des-container {
      color: #DA5864;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-align: center;
    }

    .o-success-content-tip-container {
      color: #616161;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-align: center;
    }
  }

  .o-success-bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .o-success-bottom-btn-container {
      display: flex;
      width: 238px;
      height: 40px;
      padding: 7px 0 7px 0;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      border: 1px solid #111;

      color: #111;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      cursor: pointer;
      user-select: none;
    }

    .o-success-bottom-tip-container {
      color: #4B5563;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 12px; /* 142.857% */

      span {
        color: #116ACC;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
