.my-list-mobile-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.product-item-2-btn-disabled {
  width: 104px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #AEAEAE;

  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
}
.product-item-2-btn {
  width: 104px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #60A9A6;

  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
}
