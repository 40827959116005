.my-address-detail-container {
  width: 100%;
  
  .my-address-detail-top-container {
    display: flex;
    align-items: center;
    gap: 30px;

    color: #393939;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    margin-bottom: 50px;
    
    cursor: pointer;
    user-select: none;
  }
  
  .my-address-detail-set-default-checkbox-container {
    color: #393939;
    font-feature-settings: 'salt' on;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 156.25% */
    
    margin-bottom: 40px;
    user-select: none;

    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: #111111;
      }
    }
    
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border: none;
      }
    }
  }
  
  .my-address-detail-btn {
    display: flex;
    height: 50px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 20px;
    background: #60A9A6;

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }
}
